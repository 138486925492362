<template>
  <div class="container">
    <a-button @click="$router.back()" class="mb-5"> <LeftOutlined />Kembali </a-button>
    <a-card hoverable>
      <!-- Article Image -->
      <template #cover>
        <img :src="backendUrl + article.image" alt="Article Image" />
      </template>

      <!-- Article Date and Title -->
      <div class="article-header">
        <h1>{{ article.title }}</h1>
        <p>
          {{ formattedDate }} <br />
          oleh <strong>{{ article.writer }}</strong>
        </p>
      </div>

      <!-- Article Body -->
      <div class="article-body">{{ article.body }}</div>
    </a-card>
  </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { onMounted, ref } from 'vue'
import { getListNewsById } from '../../services/axios/pulih'
import { LeftOutlined } from '@ant-design/icons-vue'
export default {
  components: {
    LeftOutlined,
  },
  setup() {
    const article = ref({
      id: '',
      title: '',
      body: '',
      image: '',
      createdAt: '',
    })
    const route = useRoute()
    const router = useRouter()
    const backendUrl = ref('https://pulih-api-prod.digitala.id/')

    // Function to format the article body
    const formatBody = text => {
      return text.replace(/\n/g, '<br/>')
    }

    // Formatting the createdAt date
    const formatDate = dateString => {
      const options = { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' }
      return new Date(dateString).toLocaleDateString('id-ID', options)
    }

    const formattedDate = ref('')

    onMounted(async () => {
      const id = route.params.id
      try {
        const response = await getListNewsById(id)
        if (!response) {
          router.push('/article')
        }
        article.value = response
        formattedDate.value = formatDate(article.value.createdAt)
      } catch (error) {
        console.error('Error fetching article:', error)
        router.push('/article')
      }
    })

    return {
      article,
      backendUrl,
      formattedDate,
      formatBody,
    }
  },
}
</script>

<style scoped>
.container {
  padding: 20px;
}

.article-header {
  /* text-align: center; */
}

.article-header p {
  font-size: 14px;
  color: gray;
}

.article-header h1 {
  font-size: 24px;
  font-weight: bold;
}

.article-body {
  /* padding: 15px; */
  font-size: 16px;
  color: #333;
  white-space: pre-wrap;
}

.article-body br {
  margin-bottom: 10px;
}

a-card {
  max-width: 800px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .article-header {
    /* text-align: center; */
  }
}

@media (min-width: 1024px) {
  .article-header {
    text-align: left;
  }
}
</style>
